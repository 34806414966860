import { ROUTES } from './constants';
import { EUserAuthority } from '../types';

/**/
export const ROUTE_ITEMS = {
  HOME: {
    path: ROUTES.HOME,
    title: 'Home',
    icon: 'home'
  },
  MAP: {
    path: ROUTES.MAP,
    title: 'Map',
    icon: 'map'
  },
  HARVESTING: {
    path: ROUTES.HARVESTING,
    title: 'Harvesting',
    icon: 'tote'
  },
  VESSELS: {
    path: ROUTES.VESSELS,
    title: 'Vessels',
    icon: 'vessel'
  },
  HISTORICAL_CATCH: {
    path: ROUTES.HISTORICAL_CATCH,
    title: 'Historical Catch',
    extra: 'New',
    icon: 'historical-catch'
  },
  HISTORICAL_TRACK: {
    path: ROUTES.HISTORICAL_TRACK,
    title: 'Historical Track',
    icon: 'route'
  },
  HISTORICAL_TRACK_NEW: {
    path: ROUTES.HISTORICAL_TRACK,
    title: 'Historical Track',
    extra: 'New',
    icon: 'route'
  },
  BIOLYTICS: {
    path: ROUTES.BIOLYTICS,
    title: 'Biolytics',
    icon: 'biolytics'
  },
  ANALYTICS: {
    path: ROUTES.ANALYTICS,
    title: 'Analytics',
    icon: 'bar-chart'
  },
  FARMS: {
    path: ROUTES.FARMS,
    title: 'Facilities',
    icon: 'items'
  },
  COMPANIES: {
    path: ROUTES.COMPANIES,
    title: 'Companies',
    icon: 'companies'
  },
  SERVICES: {
    path: ROUTES.SERVICES,
    title: 'Service Providers',
    icon: 'table'
  },
  EVENTS: {
    path: ROUTES.EVENTS,
    title: 'Events',
    icon: 'calendar-dates'
  },
  CRM: {
    path: ROUTES.CRM,
    title: 'CRM',
    icon: 'case'
  },
  NEWS: {
    path: ROUTES.NEWS,
    title: 'News',
    icon: 'newspaper'
  },
  ACTIVITY_PROFILE: {
    path: ROUTES.ACTIVITY_PROFILE,
    title: 'Activity Profile',
    icon: 'activity-profile'
  },
  CUSTOM_REPORT: {
    path: ROUTES.CUSTOM_REPORT,
    title: 'ScanBio',
    icon: 'bar-chart',
    authorities: [EUserAuthority.VIEW_USER_CUSTOM_SCANBIO]
  }
};
